/*
 * @Author: Tiger
 * @Date: 2020-07-10 06:30:33
 * @LastEditTime: 2020-11-10 10:23:31
 * @LastEditors: Please set LastEditors
 * @Description: About Us
 * @FilePath: /official_website/src/pages/about.js
 */ 
import React from 'react'
import DetailNews from '../components/DetailNews'

const NewsDetailPage = () => {
  return (
    <DetailNews />
  )
}

export default NewsDetailPage
