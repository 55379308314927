/*
*交付管理-组建项目团队
*qiufh@bocspace.cn
*/
import React from 'react'
import {inject, observer} from 'mobx-react'
import { Link } from 'gatsby'
import * as mobx from 'mobx'
import { Container, Row, Col} from 'react-bootstrap'
import { Pagination, Popover, Affix } from 'antd'
import moment from 'moment'
import url from 'url'
import {Base64} from 'js-base64'
import Layout from '../components/layout'
import JournalStore from '../store/journal/JournalStore'

@observer
export default class DetailNews extends React.Component {
	constructor(props) {
        super(props)
		this.store = new JournalStore();
	}

	componentWillReceiveProps(nextProps, nextContext) {

	}



	componentDidMount() {
        this.getNewsDetails();
	}

	componentWillMount(){
		

	}

	getNewsDetails=()=>{
		let search = window.location.href
		const newsId = url.parse(search, true).query.id;
		this.store.getNewsJournalById({
			"journalMainId": newsId
		})

	}

	removePreFixTitle=(title, prefix, fixLength)=>{
       return title.substring(title.indexOf(prefix)+fixLength)
	}


	render() {

const {newsDetail} = this.store;
const ndObj = mobx.toJS(newsDetail);
const journalSubStr = ndObj.journalSub!==undefined?JSON.parse(JSON.stringify(Base64.decode(ndObj.journalSub))):"{}";
console.log("ndObj=>",ndObj)
const journalMain = ndObj.journalMain!==undefined?ndObj.journalMain:{"title":""}
const journalSub=JSON.parse(journalSubStr)

		return (
			<Layout>
				<Affix offsetTop="0">
				<Container className="news-types2" fluid >
					
				</Container>
				</Affix>
				<Container className="news-detail"   fluid >
                <div className="news-header">
					<div className="news-date">
						<div className="date-month">{moment(journalMain.createTime).format('DD/MM')}</div>
						<div className="date-year">{moment(journalMain.createTime).format('YYYY')}</div>
					</div>
					<div className="news-title-zone">
						<div className="news-title">
							<div>
							{this.removePreFixTitle(journalMain.title, "-新闻稿-", 5)}
							</div>
						</div>
						<div className="date-line"></div>
						<div className="sub-info">
						  published time: {moment(journalMain.createTime).format('HH:MM:ss')}
						</div>
					</div>
				</div>
				<div className="news-content" dangerouslySetInnerHTML={{__html:journalSub.content}}></div>
				</Container>
			</Layout>
		)
	}
}



